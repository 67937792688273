<template>
    <div id="new-resident" class="data-list-container">

        <div class="form-header">Nouveau résident</div>
        <div class="form-legend">
            Renseigner les informations suivante afin
            de créer le profil du nouveau résident.
        </div>

        <HCard class="form">

            <!--step-selector
                :steps="steps"
                :selected="stepSelected"
                v-on:step-clicked="onStepClick"
            />-->

            <div class="form-title">Identité du résident</div>
            <div class="civility">
                <vs-radio
                    class="female"
                    v-model="resident.civility"
                    vs-name="civility"
                    vs-value="Mme"
                    data-cy="civility-female-radio"
                >Madame</vs-radio>
                <vs-radio
                    class="male"
                    v-model="resident.civility"
                    vs-name="civility"
                    vs-value="M"
                    data-cy="civility-male-radio"
                >Monsieur</vs-radio>
            </div>
            <div
                class="form-field-error"
                v-if="fieldErrors.civility"
            >{{ fieldErrors.civility }}</div>

            <div class="vx-row">
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">NOM</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            autofocus
                            class="form-input tw-w-full last-name"
                            v-model="resident.lastName"
                            type="text"
                            placeholder="Nom du résident(e)"
                            v-on:keyup="onLastNameChange"
                            data-cy="lastName-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.lastName"
                        >{{ fieldErrors.lastName }}</div>
                    </div>
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Numéro de chambre</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            v-model="resident.roomNumber"
                            type="text"
                            placeholder="Chambre attitré au résident"
                            v-on:keyup="onRoomChange"
                            data-cy="room-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.roomNumber"
                        >{{ fieldErrors.roomNumber }}</div>
                    </div>
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Email</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            v-model="resident.contactEmail"
                            type="text"
                            placeholder="nomdumail@chro-ehpad.com"
                            data-cy="email-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.email"
                        >{{ fieldErrors.email }}</div>
                    </div>
                </div>
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Prénom</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            v-model="resident.firstName"
                            type="text"
                            placeholder="Prénom du résident(e)"
                            v-on:keyup="onFirstNameChange"
                            data-cy="firstName-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.firstName"
                        >{{ fieldErrors.firstName }}</div>
                    </div>
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Numéro de téléphone</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            v-model="resident.contactPhoneNumber"
                            type="text"
                            placeholder="+33"
                            @focus="onPhoneFocus"
                            v-on:keyup="onPhoneChange"
                            data-cy="phone-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.phone"
                        >{{ fieldErrors.phone }}</div>
                    </div>
                </div>
            </div>

            <div class="form-separator"></div>

            <div class="form-title">Responsable du résident</div>
            <div class="form-legend">Renseigner les informations suivantes afin de rattacher le profil du résident</div>

            <tutor-selector
                :selected="resident.tutorId"
                v-on:change="onTutorChange"
                data-cy="tutor-selector"
            />

            <div class="form-actions">
                <vs-button
                    class="save-btn"
                    @click="onSaveBtnClick"
                    :disabled="!isFieldsValidBtn"
                    data-cy="save-btn"
                >Enregistrer</vs-button>
            </div>

        </HCard>

    </div>
</template>

<script>
import moment from 'moment'
import async from 'async'

import {
    capitalize
} from '@/modules/strings'
import Regexes from '@/constants/regexes'
import Forms from '@/constants/forms'
import Routes from '@/constants/routes'

import {
    HCard
} from '@happytal/bo-ui-library'
import TutorSelector from '@/components/selectors/TutorSelector'

export default {
    name: 'NewResident',
    components: {
        HCard,
        TutorSelector
    },
    data () {
        return {
            locked: false,
            resident: {
                institutionId: 0,
                civility: null,
                firstName: '',
                lastName: '',
                roomNumber: '',
                contactEmail: '',
                contactPhoneNumber: '',
                comment: '',
                tutorId: null
            },
            stepSelected: 'identity',
            steps: [
                { id: 'identity', label: 'etape 1' },
                { id: 'infos', label: 'etape 2' },
                { id: 'bank', label: 'etape 3' }
            ],
            fieldErrors: {
                civility: '',
                lastName: '',
                firstName: '',
                roomNumber: '',
                email: '',
            },
            loaded: false
        }
    },
    computed: {
        isFieldsValidBtn() {
            if (!this.resident.civility || ! this.resident.lastName || !this.resident.firstName) {
                return false
            }
            return true
        },
    },
    methods: {
        clear () {
            this.resident = {
                institutionId: 0,
                civility: null,
                firstName: '',
                lastName: '',
                roomNumber: '',
                contactEmail: '',
                contactPhoneNumber: '',
                comment: '',
                tutorId: null
            }
        },
        onPhoneFocus (e) {
            if (!this.resident.phone) {
                this.resident.phone = '+33'
            }
        },
        onFirstNameChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }

            const firstName = this.resident.firstName
            if (firstName) {
                this.resident.firstName = capitalize(firstName, true)
            }
        },
        onLastNameChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }
            const lastName = this.resident.lastName
            if (lastName) {
                this.resident.lastName = lastName.toUpperCase()
            }
        },
        onPhoneChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }
            const phone = this.resident.contactPhoneNumber
            if (phone) {
                this.resident.phone = phone.slice(0, 12)
            }
        },
        onRoomChange (e) {
            const room = this.resident.roomNumber
            if (room) {
                this.resident.roomNumber = room.slice(0, 15)
            }
        },
        onTutorChange (tutorId) {
            if (tutorId == 'create') {
                this.$store.commit('dataForms/SET_FORM', {
                    formId: Forms.NewResident,
                    data: this.resident
                })
                this.saved = true
                this.$router.push({
                    name: Routes.NewReferant
                })
                return
            }
            this.resident.tutorId = tutorId
        },
        isFieldValid () {
            this.fieldErrors = {}
            const civility = this.resident.civility
            if (!civility) {
                this.fieldErrors.civility = `Vous devez sélectionner une civilité.`
                return false
            }
            const lastName = this.resident.lastName
            if (!lastName || !Regexes.LastName.test(lastName)) {
                this.fieldErrors.lastName = `Le nom est invalide.`
                return false
            }
            const firstName = this.resident.firstName
            if (!firstName || !Regexes.FirstName.test(firstName)) {
                this.fieldErrors.firstName = `Le prénom est invalide.`
                return false
            }
            const phone = this.resident.contactPhoneNumber
            if (phone && !Regexes.Phone.test(phone)) {
                this.fieldErrors.phone = `Le numéro de téléphone est invalide.`
                return false
            }
            const roomNumber = this.resident.roomNumber
            if (roomNumber && !Regexes.RoomNumber.test(roomNumber)) {
                this.fieldErrors.roomNumber = `Le numéro de chambre est invalide.`
                return false
            }
            const email = this.resident.contactEmail
            if (email && !Regexes.Email.test(email)) {
                this.fieldErrors.email = `L'email est invalide.`
                return false
            }
            return true
        },
        goToResidentList () {
            this.$router.push('/residents')
        },
        deleteAllForms () {
            this.$store.commit('dataForms/DELETE_FORM', {
                formId: Forms.NewResident
            })
            this.$store.commit('dataForms/DELETE_FORM', {
                formId: Forms.NewReferant
            })
        },
        onSaveBtnClick (e) {
            if (this.locked || !this.isFieldValid()) {
                return
            }
            this.locked = true
            this.resident.institutionId = parseInt(this.getInstitutionId(), 10)
            var residentToSend = _.omit(this.resident, [
                'tutorId'
            ])
            this.$store.dispatch('dataResidents/createResident', {
                resident: residentToSend
            }).then((newResident) => {
                this.saved = true
                this.deleteAllForms()
                this.showInfoToast(`Le résident a bien été créé.`)
                const tutorId = this.resident.tutorId
                if (!tutorId) {
                    this.locked = false
                    this.goToResidentList()
                    return
                }
                this.$store.dispatch('dataResidents/updateTutor', {
                    residentId: newResident.id,
                    tutor: {
                        id: tutorId
                    }
                }).then(() => {
                    this.showInfoToast(`Le tuteur a bien été rattaché.`)
                    this.locked = false
                    this.goToResidentList()
                },(error) => {
                    this.showErrorToast(`Une erreur s'est produite (${error}).`)
                    this.locked = false
                })
            },(error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
                this.locked = false
            })
        },
        /*onStepClick (step) {
            this.stepSelected = step.id
        },*/
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getInstitutionById (institutionId) {
            return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
        },
        getCurrentInstitution () {
            return this.getInstitutionById(this.getInstitutionId())
        },
        getFormById (formId) {
            return this.$store.getters['dataForms/getFormById'](formId)
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : 5000,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        this.loaded = false
        async.series([
                (callback) => {
                    if (!this.$store.state.dataInstitutions.isRegistered) {
                        this.$store.dispatch('dataInstitutions/getInstitutions').then(() => {
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
                this.clear()
                this.locked = false
                this.saved = false
                var formData = this.getFormById(Forms.NewResident)
                if (formData) {
                    this.resident = formData
                }
                else {
                    const cInstitution = this.getCurrentInstitution()
                    if (cInstitution) {
                        this.resident.contactPhoneNumber = _.get(cInstitution, 'address.phoneNumber', '')
                        this.resident.contactEmail = _.get(cInstitution, 'address.email', '')
                    }
                }
                formData = this.getFormById(Forms.NewReferant)
                if (formData) {
                    this.resident.tutorId = formData.id
                }
                this.loaded = true
            }
        )
    },
    beforeRouteLeave (to, from, next) {
        if (this.saved) {
            next()
            return
        }
        this.$vs.dialog({
            type: 'confirm',
            color: 'primary',
            title: `Annuler la création`,
            text: `Êtes-vous sûr de vouloir quitter la page ?`,
            acceptText: `Oui`,
            cancelText: `Non`,
            accept: () => {
                this.deleteAllForms()
                next()
            },
            cancel: () => {
                next(false)
            }
        })
    }
}
</script>

<style lang="scss">
#new-resident {
    .back-btn {
        margin: 0px 0px 20px 0px;
    }
    .civility {
        display: flex;
        margin: 33px 0px 0px 0px;

        .male {
            margin: 0px 0px 0px 52px;
        }
        .female {

        }
    }
    .form-field-error {
        font-size: 1rem;
    }
}
</style>
